import React from "react";
import "./styles.scss";
import { StaticImage } from "gatsby-plugin-image";
import VarunPhilosophyImg from "../../../images/workwithusimages/varunphil.webp";
import IshuPhilosophyImg from "../../../images/workwithusimages/ishuphil.webp";
import BanerMob from "../../../images/workwithusimages/philosophyMob2.png";
import BrainIcon from "../../../images/workwithusimages/socialIcons/brainIcon.webp";

const index = () => {
  const openYoutube = () => {
    window.open("https://youtu.be/wK_HAPSpZbM", "_blank");
  };
  const handleClick = () => {
    const element = document.getElementById("jobOpenings");

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <div className="wwu-philosophy">
      <h2 className="wwu-phil-banner">
        <img src={BrainIcon} alt="philosophy-icon" className="brainIcon" loading="lazy" />
        Our Philosophy - Scaling with Soul
        <StaticImage
          className="wwu-phil-undeline"
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opdx7v.png"
        />
      </h2>
      <div className="phil-body">
        <div className="phil-banner-sec mobile-hide">
          <div className="phil-img-card">
            <div className="wwu-name">Eshu Sharma</div>
            <div className="wwu-post">Co-Founder & Head of Academics</div>
            <div className="wwu-learning">
              <b>Currently learning: </b>
              Norwegian, Python, Scriptwriting, Music Production, Human
              Biomechanics, Skiing{" "}
            </div>
          </div>
          <img
            src={IshuPhilosophyImg}
            loading="lazy"
            className="philosophy-ceo-img"
          />
          <div className="phil-img-card-2">
            <div className="wwu-name">Varun Satia</div>
            <div className="wwu-post">Founder & CEO</div>
            <div className="wwu-learning">
              <b>Currently learning: </b>
              React JS, The history of India’s cities, The science of how we
              learn
            </div>
          </div>
          <img
            src={VarunPhilosophyImg}
            loading="lazy"
            className="philosophy-ceo-img1"
          />
        </div>
        <div className="phil-banner-sec pc-none">
          <img src={BanerMob} className="banner-philo-mob" loading="lazy" />
        </div>
        <div className="phil-content-sec">
          <p>
            Scaling with Soul, to us, means we always
            <b> prioritise student outcomes–</b>
            specifically our Placement Rate (percentage of students placed)-
            over other metrics, such as number of enrolments or company
            valuation in the coming year. 
          </p>
          <p>
            {" "}
            This is, of course, easier said than done. There are painful choices
            to be made at every stage, from admissions to training to
            placements, that wouldn’t have been needed were we willing to give
            up on either - high quality or scale.
          </p>{" "}
          <p>
            But in the long run, we believe that our obsession over these
            student outcomes will earn Kraftshala the trust of Indian students,
            their families, recruiters and the broader ecosystem.
          </p>{" "}
          <p>
            That’s why we make each of our Placement Reports public, capturing
            how many students are placed in each batch, in what companies, at
            what salaries etc. We are proud to be one of the largest, if not the
            largest already, in terms of<b> actual, verifiable placements</b>.
            And in terms of Placement Rate, the metric that matters most to us,
            we are the absolute best in edtech. In the last 3 years, we have
            placed more than 1800+ students with a Placement Rate of 94% (and
            improving).
          </p>
          <p>
            <b> This is our long game- to Scale with Soul</b>. We invite you to
            join us for the play.
          </p>
          <div className="mic-sect">
            <div className="mic-text">
              Listen to Varun talk about Kraftshala and the problem we’re trying
              to solve in more detail here:
              <button className="wwu-btn-lisen" onClick={openYoutube}>
              Listen Now &gt;&gt;<span className="pc-none">&gt;&gt;</span>
              </button>
            </div>
          </div>
          <button style={{marginTop: '2em'}} className="wwu-btn" onClick={handleClick}>
              See open positions
          </button>
        </div>
      </div>
    </div>
  );
};

export default index;
