import Bgimg1 from "../../../images/workwithusimages/resp1.webp";
import Bgimg2 from "../../../images/workwithusimages/resp2.webp";
import Bgimg3 from "../../../images/workwithusimages/resp3.webp";
import Bgimg4 from "../../../images/workwithusimages/resp4.webp";
import bgMob1 from "../../../images/workwithusimages/interviewImg1.svg";
import bgMob2 from "../../../images/workwithusimages/interviewImg2.svg";
import bgMob3 from "../../../images/workwithusimages/interviewImg3.svg";
import bgMob4 from "../../../images/workwithusimages/interviewImg4.svg";

export const data = [
  {
    title: 'Prepare your responses',
    desc: `Understand the key requirements for the position and think of examples where you have demonstrated those skills. At Kraftshala, we want to understand not only why you are suitable for the role, your career progression and your achievements, but also what value you can bring to the overall business..`,
    img: Bgimg1,
    imgMob: bgMob1
  },
  {
    title: 'Understand our proposition',
    desc: `Research Kraftshala, what we do as a business, who our competitors and customers are, our products, and our future opportunities and challenges in the Ed-tech space. `,
    img:Bgimg2,
    imgMob: bgMob2
  },
  {
    title: 'Be yourself',
    desc: `At Kraftshala, you can truly bring your authentic self to work. We continuously strive to develop a diverse and inclusive culture and look to hire candidates from a variety of backgrounds. Share what you are passionate about, your future aspirations and what motivates you.`,
    img:Bgimg3,
    imgMob: bgMob3
  },
  {
    title: 'Get to know us',
    desc: `Interviews are a two-way conversation, so take the opportunity to ask the Hiring team any questions you have regarding the role, our culture, and values. This would help you decide whether we fit in your roadmap just yet, as much as it would help us decide whether or not we can bring you on board.`,
    img:Bgimg4,
    imgMob: bgMob4
  },
  
  
];