import React from "react";
import { data } from "./data";

const cardsMob = () => {
  return (
    <div className="project-card-container">
      {data.map((step, index) => (
        <div
          id={`sticky-card-${index + 1}`}
          key={index}
          className="sticky-card"
        >
          <div className="wwu-step-card" style={{background: `url(${step.img})`}}>
            <div className="wwu-p-card">
              <div className="wwu-p-card-head">{step.title}</div>
              <div className="wwu-p-card-desc">{step.desc}</div>
            </div>
          </div>
          <div className="wwu-step-card-mob">
            <img src={step.imgMob} className="wwu-step-card-mob-img" loading="lazy" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default cardsMob;
