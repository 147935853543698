import Pin1 from "../../../images/workwithusimages/pin1.webp";
import Pin2 from "../../../images/workwithusimages/pin2.png";

export const invertors = [
  {
    pin: Pin1,
    title: `Phanindra Sama`,
    bullet: `<li>Founder / Ex- CEO at<span class="wwu-invest-card-ylo"> Redbus</span>. Advisor at<span class="wwu-invest-card-ylo"> WestBridge Capital</span>. Former Chief Innovation Officer,<span class="wwu-invest-card-ylo"> Govt of Telangana</span></li>`,
  },
  {
    pin: Pin2,
    title: `Amit Kumar<br/> Agarwal, Saurabh <br/>Garg & Akhil Gupta`,
    bullet: `<li>Co-founders at <span class="wwu-invest-card-ylo"> Nobroker.com </span>(the world’s largest NoBrokerage Property Site and India’s first realty startup unicorn)</li>`,
  },
  {
    pin: Pin1,
    title: `Pallav Jain and<br/> Sarfaraz Khimani`,
    bullet: `<li>Co-CEOs of<span class="wwu-invest-card-ylo"> Performics</span>, India's largest SEO/ performance marketing agency</li>`,
  },
  {
    pin: Pin1,
    title: `Sujayath Ali`,
    bullet: `<li>Co-founder at <br/>    <span class="wwu-invest-card-ylo">ShopUp </span> <br/>and <span class="wwu-invest-card-ylo">Voonik</span></li>`,
  },
  {
    pin: Pin1,
    title: `Doreswamy <br/>Nandkishore`,
    bullet: `<li>Former <span class="wwu-invest-card-ylo">Fortune 100 CEO</span>, Head of Asia, Oceania, Africa for <span class="wwu-invest-card-ylo">Nestlé S.A</span>., Professor at<span class="wwu-invest-card-ylo"> ISB</span></li>`,
  },
];
