import img1 from "../../../images/workwithusimages/seleImg1.png";
import img2 from "../../../images/workwithusimages/seleImg2.png";
import img3 from "../../../images/workwithusimages/seleImg3.png";
import img4 from "../../../images/workwithusimages/seleImg4.png";
import img5 from "../../../images/workwithusimages/seleImg5.png";
import img6 from "../../../images/workwithusimages/seleImg6.png";

import PaperPin0 from "../../../images/workwithusimages/paperPin1.png";
import PaperPin1 from "../../../images/workwithusimages/paperPin2.png";
import PaperPin2 from "../../../images/workwithusimages/paperPin3.png";

export const section = [
  {
    image: img1,
    pin: PaperPin0,
    title: `Application Screening`,
    desc: `After reviewing your profile, if we find you to be a suitable match for the role, we will get on a <b>brief exploratory call</b> with you to <b>understand more about your experience and share details of the selection process</b>.`,
  },
  {
    image: img2,
    pin: PaperPin1,
    title: `Skill Assessment interview`,
    desc: `This would be a conversation where <br/>we’ll <b>deep dive into your prior experience</b>, and you can expect to be evaluated on the competencies <br/>needed  for the role.`,
  },
  {
    image: img3,
    pin: PaperPin2,
    title: `Technical Assignment`,
    desc: `For most (mid-senior or senior) roles,<br class="mobile-none"/> we will ask you to work on and submit <b><br class="mobile-none"/>a skill-based assignment that would be reflective of the work you’d be responsible for if you get shortlisted</b>. This is so you can get familiar with the work, and also for us to understand your competency level for the same.`,
  },
  {
    image: img4,
    pin: PaperPin1,
    title: `Hiring Manager Interview`,
    desc: `This would be a chance for you to <b>meet your prospective manager / team lead</b>. It would be a mix of technical as well as a culture-fit conversation.`,
  },
  {
    image: img5,
    pin: PaperPin2,
    title: `Culture Fit Conversation`,
    desc: `This would be <b>a conversation with our Founder / Senior Leadership</b>  to ensure there’s a fit with the Kraftshala Kode`,
  },
  {
    image: img6,
    pin: PaperPin1,
    title: `Final Result`,
    desc: `If all goes well, we will extend an offer to you. If not, we’ll let you know that too. <b> You will hear back from us regardless of the outcome</b>.`,
  },
  
  
];
