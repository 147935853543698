
export const newsData = [
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2oq7ozi.png",
    compImage: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2oq6dxa.png",
    desc: 'Kraftshala partners with Nestlé to inspire people to pursue sales careers',
    descShort: 'Kraftshala partners with Nestlé to inspire',
    readMore: true,
    link: 'https://www.adgully.com/kraftshala-partners-with-nestle-to-inspire-people-to-pursue-sales-careers-145028.html'
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2oq3t6w.png",
    compImage: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2oq2t1b.png",
    desc: 'Kraftshala announces free training sessions for professionals',
    descShort: 'Kraftshala announces free training ',
    readMore: true,
    link: 'https://www.afaqs.com/companies/kraftshala-announces-free-training-sessions-for-professionals-and-b-school-students'
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2oq0s27.png",
    compImage: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opzq0y.png",
    desc: 'Hindi Learners Can Benefit From These New Age Courses In Native Language',
    descShort: 'Hindi Learners Can Benefit From These New',
    link: 'https://www.ndtv.com/education/hindi-learners-can-benefit-from-these-new-age-courses-in-native-language-5675596/amp/1'
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opx8e8.png",
    compImage: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opvxl5.png",
    desc: 'Kraftshala launches PG Program in Sales and Business leadership',
    descShort: 'Kraftshala launches PG Program in Sales and',
    readMore: true,
    link: 'https://education21.in/kraftshala-launches-pg-program-in-sales-and-business-leadership/'
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opujvc.png",
    compImage: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2optfjc.png",
    desc: 'Unlocking Career Potential: Kraftshala Teams Up with Adarsh Menon',
    descShort: 'Unlocking Career Potential: Kraftshala Teams',
    readMore: true,
    link: 'https://www.edukida.in/trending/unlocking-career-potential-kraftshala-teams-up-with-zoomcars-adarsh-menon-for-sales-and-business-career-session/'
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2ops2lj.png",
    compImage: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opr13z.png",
    desc: 'Varun Satia | Founder & CEO Kraftshala | India A-List | NewsX',
    descShort: 'Varun Satia | Founder & CEO Kraftshala',
    link: 'https://www.youtube.com/watch?si=ic0LGN6ht07VNc-A&v=rYchpbMMJr0&feature=youtu.be'
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2oppjp5.png",
    compImage: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opna2f.png",
    desc: 'How Kraftshala prepares FMCG, SaaS, and marketing professionals',
    descShort: 'How Kraftshala prepares FMCG, SaaS',
    readMore: true,
    link: 'https://cxotoday.com/interviews/tailored-training-excellence-how-kraftshala-prepares-fmcg-professionals-for-industry-challenges/'
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2oplr2x.png",
    compImage: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opj8s0.png",
    desc: 'Kraftshala to empower mid-level agency managers for leadership roles',
    descShort: 'Kraftshala to empower mid-level agency ',
    readMore: true,
    link: 'https://www.medianews4u.com/kraftshala-to-empower-mid-level-agency-managers-for-leadership-roles-with-the-leadership-catalyst-series/'
  },
  
];
