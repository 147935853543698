import React from "react";
import Seo from "../../components/Seo/seo";
import Layout from "../../components/Layout/layout";
import HeroSection from "../../components/WorkWithUs/HeroSection/heroSection";
import JobOpenings from "../../components/WorkWithUs/JobOpenings/jobOpenings";
import Philosophy from "../../components/WorkWithUs/Philosophy";
import Education from "../../components/WorkWithUs/Education";
import Impact from "../../components/WorkWithUs/Impact";
import SalesAndMarketing from "../../components/WorkWithUs/SalesAndMarketing";
import Experience from "../../components/WorkWithUs/Experience";
import KSKode from "../../components/WorkWithUs/KSKode";
import Investors from "../../components/WorkWithUs/Investors";
import Selection from "../../components/WorkWithUs/Selection";
import Careers from "../../components/WorkWithUs/Careers";
import News from "../../components/WorkWithUs/News";
import Social from "../../components/WorkWithUs/Social";

import "./styles.scss";

const WorkWithUs = () => {
  return (
    <React.Fragment>
      <Seo
        title="Kraftshala Careers - Jobs at Kraftshala"
        description="Join us at Kraftshala - India's leading ed-tech platform that is consistently transforming thousands of lives through job-linked training."
        url="https://www.kraftshala.com/kraftshala-careers/"
      />

      <div className="main-work-with-us-page">
        <Layout>
          <HeroSection />
          <Philosophy />
          <Education />
          <Impact />
          <SalesAndMarketing />
          <Experience />
          <KSKode />
          <Investors />
          <Selection />
          <Careers />
          <JobOpenings />
          <News />
          <Social />
        </Layout>
      </div>
    </React.Fragment>
  );
};

export default WorkWithUs;
