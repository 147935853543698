import React from "react";
import "./styles.scss";
import { StaticImage } from "gatsby-plugin-image";
import CardSection from "./cardsSec";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const index = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div className="wwu-impact">
      <div className="wwu-impact-head">Impact we’ve created so far:</div>
      <StaticImage
        className="wwu-impact-undeline"
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opdx7v.png"
      />

      <div className="wwu-impact-card">
        <div className="imapct-card-text">
          <b>We’re the largest employment-creating institution in the country</b> for entry-level roles, with our flagship program - The Marketing Launchpad. While there’s a long road ahead to really be able to make a dent in the plummeting employment figures, in just the last 3 years, this is what we’ve been able to achieve
          {inView && <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            className="wwu-impact-svg"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.49902 1.99991C11.444 0.0614858 19.1807 1.05929 23.8879 10.9444C27.2964 18.1021 26.999 26.2597 26.999 33.9999"
              stroke="#505050"
              stroke-width="1.5"
              stroke-linecap="round"
              className="arrow-line"
            />
            <path
              d="M19.999 28C22.0823 29.4274 24.5142 30.8595 26.0824 32.8889C26.138 32.9609 26.5937 34.2125 26.8879 33.8889C27.709 32.9857 27.9952 31.5093 28.8324 30.5278C30.1305 29.0059 31.9529 27.765 33.499 26.5"
              stroke="#505050"
              stroke-width="1.5"
              stroke-linecap="round"
              className="arrow-inventory"
            />
          </svg> }</div>
        <div className="data-sec-wwu"  ref={ref}>
          {inView && <>
            <motion.div
              variants={{
                hidden: { opacity: 0, y: -160, x: 0},
                visible: { opacity: 1, y: 0, x: 0 },
              }}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.75, delay: 1 }} className="data">
              <div className="value">94%</div>
              <StaticImage
                src="../../../images/workwithusimages/greyUndeline.png"
                alt="underline"
                className="grey-underline mobile-none"
              />
              <StaticImage
                src="../../../images/workwithusimages/impactMobUnderline.png"
                alt="underline"
                className="grey-underline pc-none"
              />
              <div className="data-text"> Placement rate</div>
            </motion.div>
            <div className="data">
              <div className="wwu-hrLine"></div>
            </div>
            <motion.div
              variants={{
                hidden: { opacity: 0, y: -160, x: 0},
                visible: { opacity: 1, y: 0, x: 0 },
              }}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.75, delay: 1.25 }} className="data">
              <div className="value">1800+</div>
              <StaticImage
                src="../../../images/workwithusimages/greyUndeline.png"
                alt="underline"
                className="grey-underline mobile-none"
              />
              <StaticImage
                src="../../../images/workwithusimages/impactMobUnderline.png"
                alt="underline"
                className="grey-underline-2 pc-none"
              />
              <div className="data-text"> Students Placed </div>
            </motion.div>
            <div className="data">
              <div className="wwu-hrLine"></div>
            </div>
            <motion.div
              variants={{
                hidden: { opacity: 0, y: -160, x: 0},
                visible: { opacity: 1, y: 0, x: 0 },
              }}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.75, delay: 1.5 }} className="data">
              <div className="value">400+</div>
              <StaticImage
                src="../../../images/workwithusimages/greyUndeline.png"
                alt="underline"
                className="grey-underline mobile-none"
              />
              <StaticImage
                src="../../../images/workwithusimages/impactMobUnderline.png"
                alt="underline"
                className="grey-underline-2 pc-none"
              />
              <div className="data-text">Recruiting Partners</div>
            </motion.div> </>}
        </div>
      </div>
      <CardSection />
    </div>
  );
};

export default index;
