import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect,useState, useRef } from "react";
import "./styles.scss";
import { section } from "./data";
import { useInView } from "react-intersection-observer";
import PlaceIcon from "../../../images/workwithusimages/placeIcon.png";
import FlagIcon from "../../../images/workwithusimages/flagIconm.png";
import dotBg from "../../../images/workwithusimages/dots.webp";

const index = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(true);
  const containerRef = useRef(null);
  const flag = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setCanScrollNext(false);
        } else {
          setCanScrollNext(true);
        }
      },
      {
        root: null, // observe the entire viewport
        threshold: 0.1, // when 10% of the image is visible
      }
    );

    if (flag.current) {
      observer.observe(flag.current);
    }

    return () => {
      if (flag.current) {
        observer.unobserve(flag.current);
      }
    };
  }, [flag]);

  const checkScrollPosition = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;

      setCanScrollPrev(scrollLeft > 0);
      //setCanScrollNext(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const handleNext = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 300, // Scrolls 300 pixels to the right
        behavior: 'smooth'
      });
      setTimeout(checkScrollPosition, 300); // Check the scroll position after the scroll event
    }
  };

  const handlePrev = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -300, // Scrolls 300 pixels to the left
        behavior: 'smooth'
      });
      setTimeout(checkScrollPosition, 300); // Check the scroll position after the scroll event
    }
  };

  useEffect(() => {
    checkScrollPosition(); // Check the initial scroll position
    window.addEventListener('resize', checkScrollPosition); // Recheck on window resize

    return () => {
      window.removeEventListener('resize', checkScrollPosition); // Cleanup
    };
  }, []);
  useEffect(() => {
    //alert(inView);
    let paths = document.querySelectorAll(".dotted-path");
    let i = 0;
    const speed = 230;

    paths.forEach((item, index) => {
      i++;
      let pathLength = item.getTotalLength();

      item.setAttribute("stroke-dasharray", pathLength);
      item.setAttribute("stroke-dashoffset", pathLength);

      if (index === 0) {
        item.innerHTML = `<animate id='a${i}' attributeName='stroke-dashoffset' begin='1s' dur='0.1s' to='0' fill='freeze' />`;
      } else {
        item.innerHTML = `<animate id='a${i}' attributeName='stroke-dashoffset' begin='a${
          i - 1
        }.end' dur='${pathLength / speed}s' to='0' fill='freeze' />`;
      }
    });
  }, [inView]);

  return (
    <div className="wwu-selection-process">
      <div className="wwu-sub-title">Kraftshala Careers</div>
      <div className="wwu-title">  Selection Process at Kraftshala </div>
      <StaticImage
        className="wwu-select-undeline"
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opdx7v.png"
      />
      <div className="wwu-desc"  ref={ref}>
        As a small team, it’s imperative that we get to know each other well
        before taking the leap. And we want to ensure you succeed in your role
        within our team. Each conversation with us will last about{" "}
        <b>30-60 minutes</b>. Typically the whole process takes between 10 - 15
        business days depending on your and our schedules. This may vary
        depending on the role, however, you can expect to go through
        <b> all or some of the stages mentioned below</b> during the hiring
        process. <br />
        <br />
        <b>This is what our general selection process looks like</b>
        <svg
          className="wwu-invest-svg-3 mobile-none"
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.49902 1.99991C11.444 0.0614858 19.1807 1.05929 23.8879 10.9444C27.2964 18.1021 26.999 26.2597 26.999 33.9999"
            stroke="#505050"
            stroke-width="1.5"
            stroke-linecap="round"
            className="arrow-line"
          />
          <path
            d="M19.999 28C22.0823 29.4274 24.5142 30.8595 26.0824 32.8889C26.138 32.9609 26.5937 34.2125 26.8879 33.8889C27.709 32.9857 27.9952 31.5093 28.8324 30.5278C30.1305 29.0059 31.9529 27.765 33.499 26.5"
            stroke="#505050"
            stroke-width="1.5"
            stroke-linecap="round"
            className="arrow-inventory"
          />
        </svg>
        <svg
          className="wwu-invest-svg-3 pc-none"
          width="18"
          height="19"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.49902 1.99991C11.444 0.0614858 19.1807 1.05929 23.8879 10.9444C27.2964 18.1021 26.999 26.2597 26.999 33.9999"
            stroke="#505050"
            stroke-width="1.5"
            stroke-linecap="round"
            className="arrow-line"
          />
          <path
            d="M19.999 28C22.0823 29.4274 24.5142 30.8595 26.0824 32.8889C26.138 32.9609 26.5937 34.2125 26.8879 33.8889C27.709 32.9857 27.9952 31.5093 28.8324 30.5278C30.1305 29.0059 31.9529 27.765 33.499 26.5"
            stroke="#505050"
            stroke-width="1.5"
            stroke-linecap="round"
            className="arrow-inventory"
          />
        </svg>
      </div>
      <svg
        width="31"
        className="location-svg"
        height="59"
        viewBox="0 0 31 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0359 56.7757C7.06776 45.7087 2.6922 35.9927 2.09142 22.7757C1.81528 16.7006 2.34536 11.8075 6.03587 6.8868C8.66914 3.37578 16.3066 0.317531 20.4803 2.44236C34.7988 9.73179 29.3883 27.3482 21.647 37.109C17.2588 42.6419 16.9909 49.8657 14.0359 55.7757"
          stroke="#F1AE13"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M15.036 12.2744C9.70507 12.579 8.47663 19.9378 14.536 20.2744C16.0457 20.3583 16.9711 18.0736 17.036 16.7744C17.1443 14.6093 18.3708 13.4418 16.036 12.2744"
          stroke="#F1AE13"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>
      {inView &&<svg
        width="56"
        className="flag-svg"
        height="64"
        viewBox="0 0 56 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.377 3.67043C22.5423 3.67043 30.2676 0.573291 34.1547 3.11488C40.8213 7.47381 47.2937 8.92038 54.377 4.67043"
          stroke="#F1AE13"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M54.377 4.66992V25.6699"
          stroke="#F1AE13"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M17.377 53.6699C17.377 37.0447 18.377 20.0318 18.377 3.66992"
          stroke="#F1AE13"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M16.377 26.6699C23.9687 21.7061 30.6338 24.6035 38.9325 26.4477C43.7309 27.514 51.9283 27.5673 54.377 22.6699"
          stroke="#F1AE13"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M15.3769 51.6895C11.3429 51.6895 6.46339 51.0122 3.37694 54.1895C1.97371 55.634 2.14907 56.9616 3.48805 58.3006C7.99637 62.8089 16.9401 61.4093 22.8769 61.1895C25.4916 61.0926 30.8574 56.8982 27.2658 54.4117C24.2962 52.3558 21.7039 52.2984 18.3769 51.1895"
          stroke="#F1AE13"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>}

      {inView && <svg
        className="dotted-line-svg"
        width="1153"
        height="473"
        viewBox="0 0 1153 473"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="dotted-path"
          d="M1 1.5C4.33309 1.32572 7.66625 1.15875 11 1"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M19.5 1H31"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M42 2C44.6933 2 47.3715 2.19522 50 1.61111C51.318 1.31821 52.6487 1 54 1"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M396 9.5C398.785 9.5 401.332 9.53354 404 9"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M411 10.5H420"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M758 9.5H766.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M773.5 9.5H782"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1117 1C1119.5 1.21765 1121.99 1.36808 1124.5 1.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1133.5 3.5C1135.94 4.74307 1138.2 6.06264 1140.5 7.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1145 18C1146.11 21.7103 1146.83 25.2298 1147.5 29"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1149 42C1149.24 47.6657 1149.5 53.3283 1149.5 59"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1149.5 74V88"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1150 107V123.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1148.5 138C1148.4 141.669 1148.17 145.334 1148 149"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1147 161L1146 171"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1143 187.5C1142.74 191.358 1142.03 195.174 1141.5 199"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1138 209L1130 217"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1115 217.5C1110.29 218.814 1105.31 219.227 1100.5 220"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1074.5 221.5C1070.45 222.538 1066.14 222.569 1062 223"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1035 222.5C1030.71 223.51 1025.85 222.918 1021.5 223"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M988 222H1000.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M976.5 222C972.155 222.161 967.831 222.639 963.5 223"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M925.5 222H936"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M907 222L896 223"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M875.5 223C871.831 223.102 868.166 223.325 864.5 223.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M844.5 224H850.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M825.5 225.5C822.499 225.375 819.499 225.167 816.5 225"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M786 224H802"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M764 223L746.5 222.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M707 222H720.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M667 222H688.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M630.5 221.5H651"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M617 221.5C611.832 221.599 606.666 221.825 601.5 222"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M570.5 223H589.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M556 224C550.493 224.039 544.999 224.211 539.5 224.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M517.5 223C510.666 222.799 503.835 222.639 497 222.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M484 222.5C477.499 222.608 470.999 222.827 464.5 223"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M430.5 223H448.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M402.5 222.5H418"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M384.5 223C379.833 223.198 375.168 223.359 370.5 223.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M319 225H337"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M292.5 225C285.5 225.143 278.5 225.331 271.5 225.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M223.5 225H245"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M205.5 224.5C200.831 224.417 196.166 224.179 191.5 224"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M174 224C169.96 224.026 166.015 224.223 162 224.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M123.5 224.5H140.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M99.5 224C92.9916 224.566 86.4973 225.325 80 226"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M64 230L51 232"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M45 235C41.6514 236.55 38.6494 238.591 35.5 240.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M30 256C29.8915 258.17 29.6732 260.335 29.5 262.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M27.5 280.5C26.7618 284.998 26.1017 289.487 25.5 294"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M24.5 315.5V327.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M24 345.5V368"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M24 388C23.7061 392.556 22.7344 397.002 22 401.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M20.5 420C20.3781 423.168 20.1711 426.334 20 429.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M20 441.5V451.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M21 460.5C22.208 463.419 24.9938 465.659 27 468"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M35.5 469.5C40.6789 470.045 45.8378 470.823 51 471.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M397 453H405"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M413 453H419"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M756.5 429.5H766"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M774 431C776.166 431.179 778.332 431.345 780.5 431.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1119 426H1131"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          class="dotted-path"
          d="M1140 427C1143.83 426.726 1147.66 426.5 1151.5 426.5"
          stroke="#8D8D8D"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg> }
      <img src={dotBg} className="dot-bg pc-none" />
      <div className="wwu-selec-proc-container" ref={containerRef}>
        <img src={PlaceIcon} className="placeIconMob" />
        {section.map((process, index) => {
          return (
            <div key={index} className="wwu-selec-proc-card">
              {" "}
              <img src={process.pin} loading="lazy" className={`wwu-selec-pin wwu-selec-pin-${index}`} />
              <img src={process.image} className="wwu-selec-proc-card-img" />
              <div
                className="wwu-selection-card-head"
                dangerouslySetInnerHTML={{ __html: process.title }}
              />
              <div
                className="wwu-selection-card-txt"
                dangerouslySetInnerHTML={{ __html: process.desc }}
              />
            </div>
          );
        })}
        <img src={FlagIcon} ref={flag} className="flagIconMob" />
      </div>
      <div className="scroll-buttons">
        <button id="prevBtn" className={`prevBtn ${!canScrollPrev && 'prev-btn-disabled'}`} onClick={handlePrev} >
          
        </button>
        <button id="nextBtn" className={`nextBtn ${!canScrollNext && 'nxt-btn-disabled'}`} onClick={handleNext} >
          
        </button>
      </div>
      <div className="wwu-desc-btm">
        The interviews could be a mix of virtual and in-person conversations.{" "}
        <b style={{ marginRight: "10px" }}>
          Your recruiter will let you know if we want to meet you in person
        </b>
        <svg
          width="28"
          height="29"
          viewBox="0 0 28 29"
          className="mobile-none"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="heart"
            d="M2.74998 26.4998C2.25629 20.9046 0.083914 15.6075 1.66665 9.94426C2.94254 5.37896 8.36167 0.57408 13.4722 2.27759C15.1899 2.85017 15.9797 4.88708 16.3194 6.48592C16.4549 7.12361 16.5593 7.89724 16.5694 8.55537C16.5716 8.69604 16.2761 10.1594 16.3889 10.3054C16.501 10.4504 19.3442 9.68398 19.6805 9.6387C20.4909 9.52962 21.567 9.27252 22.2778 9.81926C24.8454 11.7944 27.0743 16.4091 25.4861 19.472C22.8034 24.6459 16.0806 25.0339 11.0694 25.7498C8.37784 26.1343 5.47427 27.2498 2.74998 27.2498"
            stroke="#F2AF13"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
        <svg
          width="13"
          height="13"
          viewBox="0 0 28 29"
          fill="none"
          className="pc-none heartMob"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.74998 26.4998C2.25629 20.9046 0.083914 15.6075 1.66665 9.94426C2.94254 5.37896 8.36167 0.57408 13.4722 2.27759C15.1899 2.85017 15.9797 4.88708 16.3194 6.48592C16.4549 7.12361 16.5593 7.89724 16.5694 8.55537C16.5716 8.69604 16.2761 10.1594 16.3889 10.3054C16.501 10.4504 19.3442 9.68398 19.6805 9.6387C20.4909 9.52962 21.567 9.27252 22.2778 9.81926C24.8454 11.7944 27.0743 16.4091 25.4861 19.472C22.8034 24.6459 16.0806 25.0339 11.0694 25.7498C8.37784 26.1343 5.47427 27.2498 2.74998 27.2498"
            stroke="#F2AF13"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default index;
