import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./styles.scss";
import TopCompanies from "./topCompanies";

const index = () => {
  return (
    <div className="wwu-sales">
      <div className="wwu-s-title">
        World's best sales and marketing companies hire our students
      </div>
      <StaticImage
        className="wwu-sales-undeline"
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opdx7v.png"
      />
      <div className="wwu-s-desc">
        We’ve been able to add{" "}
        <span className="wwu-s-ylo-txt">
          {" "}
          immense value to multiple companies{" "}
        </span>
        by helping them reduce the <br className="mobile-hide"/>
        cost and time of hiring by giving them access to trained talent that is
        ready to deliver from Day 1. This is why we’ve also become the
      </div>
      <div className="wwu-s-ylo-txt">
        largest talent provider to India’s top marketing agencies ALREADY.
      </div>
      <TopCompanies />
    </div>
  );
};

export default index;
