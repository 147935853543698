import React from "react";
import "./styles.scss";
import HeroImg from "../../../images/workwithusimages/HeroImg.webp";
import CircleImg from "../../../images/workwithusimages/heroTextCircle.webp";
import dotsLeft from "../../../images/workwithusimages/DotsBg/herolftDots.png";
import dotsRight from "../../../images/workwithusimages/DotsBg/heroRtDots.png";

function HeroSection() {
  const handleClick = () => {
    const element = document.getElementById("jobOpenings");

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <div className="wwu-header-container">
      <h1 className="wwu-welcome pc-hide">
          Welcome
        <br />
          to Kraftshala
        <img src={CircleImg} className="circle-text" loading="lazy" />
      </h1>
      <img src={HeroImg} className="wwu-hero-img" loading="lazy" />
      <div className="hero-section">
        <img src={CircleImg} className="circle-text mobile-hide" loading="lazy" />
        <div className="wwu-welcome mobile-hide">
          Welcome
          <br />
          to Kraftshala
        </div>
       
        <div className="wwu-hero-desc">
          We’re an online skilling institution committed to playing the long
          game of building a transformative educational experience for the
          masses.
        </div>
        <button className="wwu-btn-hero" onClick={handleClick}>See open positions</button>
      </div>
      <img src={dotsLeft} loading="lazy" className="HerodotsLeft" />
      <img src={dotsRight} loading="lazy" className="HerodotsRight" />
    </div>
  );
}

export default HeroSection;