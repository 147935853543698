import img1 from "../../../images/workwithusimages/kode-section/kokodeContainer1.webp";
import img2 from "../../../images/workwithusimages/kode-section/kokodeContainer2.webp";
import img3 from "../../../images/workwithusimages/kode-section/kokodeContainer3.webp";
import img4 from "../../../images/workwithusimages/kode-section/kokodeContainer4.webp";
import img5 from "../../../images/workwithusimages/kode-section/kokodeContainer5.webp";
import img6 from "../../../images/workwithusimages/kode-section/kokodeContainer6.webp";
import img7 from "../../../images/workwithusimages/kode-section/kokodeContainer7.webp";
import img8 from "../../../images/workwithusimages/kode-section/kokodeContainer8.webp";

export const values = [{
  title: `User <br class="mobile-hide"/>centricity`,
  bgImg: img1
},{
  title: `Bias for <br class="mobile-hide"/>Action`,
  bgImg: img2
},{
  title: `Problem<br class="mobile-hide"/> solving`,
  bgImg: img3
},{
  title: `Learning <br class="mobile-hide"/>Agility`,
  bgImg: img4
},{
  title: `Ambition<br class="mobile-hide"/> & courage`,
  bgImg: img5
},{
  title: `Consistency<br class="mobile-hide"/> & reliability`,
  bgImg: img6
},{
  title: `Real, open<br class="mobile-hide"/> & empathetic communication`,
  bgImg: img7
},{
  title: `Practical<br class="mobile-hide"/> Optimism`,
  bgImg: img8
}];