import sumit from "../../../images/workwithusimages/students/sumit.webp";
import sonia from "../../../images/workwithusimages/students/sonia.webp";
import kratika from "../../../images/workwithusimages/students/kratika.webp";
import srishti from "../../../images/workwithusimages/students/srishti.webp";

export const students = [{
  bannerImg: sumit,
  undelineWidth:'119px',
  course: 'The Marketing Launchpad',
  courseSub:'',
  name: 'Sumit Kakkad',
  previous: `Mechanical Engineer`,
  now: `Retention Marketing<br/> Executive @ Toothsi`,
  youtubeLink: 'https://youtube.com/watch?v=_djT0XMZV38',
  listen: 'Listen to his story'
},{
  bannerImg: sonia,
  undelineWidth:'119px',
  course: 'The Marketing Launchpad',
  courseSub:'(Emerging Talent)',
  name: 'Sonia Prasad',
  previous: `B.Com Undergraduate,<br/> UPSC aspirant`,
  now: `Digital Marketing <br/>Executive @ Qtonz <br/>Infosoft`,
  youtubeLink: 'https://youtu.be/MENQFN7qZDo',
  listen: 'Listen to her story'
},{
  bannerImg: kratika,
  undelineWidth: '57px',
  course: `PGP in Sales and <br class="mobile-none" /> Business Leadership`,
  courseSub:'',
  name: 'Kratika',
  previous: `Science graduate +<br/> BPO experience`   ,
  now: `Sales Executive Trainee <br/>@ Nestle`,
  youtubeLink: 'https://youtu.be/KtxXdlcZNw4',
  listen: 'Listen to her story'
},{
  bannerImg: srishti,
  undelineWidth: '119px',
  course: `Content and Social <br class="mobile-none" />Media Launchpad`,
  courseSub:'',
  name: 'Srishti Agnihotri',
  previous: `System Engineer <br/>@ Infosys`,
  now: `Content Marketer @<br/> Skyline Construction`,
  youtubeLink: 'https://www.youtube.com/watch?v=y-NJvUgsQ38&ab_channel=Kraftshala',
  listen: 'Listen to her story'
}];