import React, { useEffect } from 'react';
import "./styles.scss";
import Graph from "../../../images/workwithusimages/graphImg.png";
import graphGif from "../../../images/workwithusimages/graphGif.gif";
import { useInView } from "react-intersection-observer";
import dotsLeft from "../../../images/workwithusimages/DotsBg/graphLftDot.png";
import dotsRight from "../../../images/workwithusimages/DotsBg/graphRtDot.png";

const GraphSec = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
    
  useEffect(() => {}, [inView]);

  return (
    <div className="wwu-edu-graph" ref={ref}>
      <img src={dotsLeft} loading="lazy" className="graphdotsLeft" />
      <img src={dotsRight} loading="lazy" className="graphdotsRight" />
    
      {inView ? <img src={graphGif} className="graph-img" loading="lazy" /> : <img src={Graph} oading="lazy" className="graph-img" />}
      <div className="edu-graph-text">
          We currently have the <span className="edu-graph-span">highest</span>{" "}
          placement rate across all online or offline education institutes.
          That’s because we've focused on a very narrow problem statement and
          gone<span className="edu-graph-nanum"> BIG</span> on that. We’ve
          designed everything around employability by focusing on
        <span className="edu-graph-span"> 4 pillars.</span>
      </div>
    </div>
  );
};

export default GraphSec;
