import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./styles.scss";
import Marquee from "react-fast-marquee";
import LazyLoad from "react-lazyload";

export default function TopCompanies() {
  return (
    <div className="wwu-home-top-companies">
      <LazyLoad>
        <div className="mobile-hide">
          <Marquee className="marquee">
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35ig6t7.webp"
              alt="growisto"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35it1lx.webp"
              alt="atomberg"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35iuwoa.webp"
              alt="atomberg"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35j5v17.webp"
              alt="beacon"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35j7ekn.webp"
              alt="sesa"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35j8jdt.webp"
              alt="scaler"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jar3e.webp"
              alt="phisics"
            />
           
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jbsi1.webp"
              alt="schbang"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jdeu5.webp"
              alt="coursera"
            />
          </Marquee>
          <Marquee className="marquee2">
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35k4t5j.webp"
              alt="social"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35k2xaf.webp"
              alt="toothisi"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jfh0y.webp"
              alt="neeman"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jgqru.webp"
              alt="nyka"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jhmgr.webp"
              alt="masai"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jofss.webp"
              alt="tata"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jphpx.webp"
              alt="groupm"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jqxsa.webp"
              alt="performatic"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jshkn.webp"
              alt="ant"
            />
         
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jtot2.webp"
              alt="pumpkin"
            />
            {/* <StaticImage
              className="company"
              src="../../../images/home/companies/publicis.webp"
              alt="publicis"
            /> */}
          </Marquee>
        </div>
      </LazyLoad>

      <LazyLoad>
        <div className="pc-none">
          <Marquee>
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35ig6t7.webp"
              alt="growisto"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jphpx.webp"
              alt="groupm"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35it1lx.webp"
              alt="atomberg"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35j5v17.webp"
              alt="beacon"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35j7ekn.webp"
              alt="sesa"
            />
          </Marquee>
          <Marquee>
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jqxsa.webp"
              alt="performatic"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35j8jdt.webp"
              alt="scaler"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jar3e.webp"
              alt="phisics"
            />
       
     
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jbsi1.webp"
              alt="schbang"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jdeu5.webp"
              alt="coursera"
            />
          </Marquee>
          <Marquee className="marquee-mob">
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35k4t5j.webp"
              alt="social"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jshkn.webp"
              alt="ant"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jtot2.webp"
              alt="pumpkin"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35k5wz2.webp"
              alt="toothisi"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jfh0y.webp"
              alt="neeman"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jgqru.webp"
              alt="nyka"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jhmgr.webp"
              alt="masai"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35jofss.webp"
              alt="tata"
            />
          </Marquee>
         
        </div>
      </LazyLoad>
    </div>
  );
}
